import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { AnalysisLoopService } from 'services/analysis-loop-service';
import { LoopPositionHorizontalService } from 'services/loop-position-horizontal-service';
import { LoopPositionVerticalService } from 'services/loop-position-vertical-service';
import { LoopTypeService } from 'services/loop-type-service';
import { ToastService } from 'services/toast-service';
import { AnalysisLoopBase } from './analysis-loop-base';
import { CouplingService } from 'services';

@containerless
@autoinject
export class AnalysisLoopFormInlineNew extends AnalysisLoopBase {
  @bindable private analysisId: number;
  @bindable protected minimumBreakingStrength: number;

  protected currentLoopType: Models.AnalysisLoop;
  protected actualBreakingStrengthMissing = false;

  constructor(
    private analysisLoopService: AnalysisLoopService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private loopTypeService: LoopTypeService,
    protected loopPositionHorizontalService: LoopPositionHorizontalService,
    protected loopPositionVerticalService: LoopPositionVerticalService,
    couplingService: CouplingService
  ) {
    super(couplingService);
  }

  protected async createLoop() {
    try {
      this.analysisLoop.AnalysisId = this.analysisId;

      if (this.selectedCouplingId && this.hasConnectionChecked()) {
        this.analysisLoop.CouplingId = this.selectedCouplingId;
      } else {
        this.analysisLoop.CouplingId = null;
        delete this.analysisLoop.Coupling;
      }

      await this.analysisLoopService.post(this.analysisLoop);
      this.eventAggregator.publish('loopListReset', 1);
      this.eventAggregator.publish('loopFormNewClose', 1);
      this.toastService.showSuccess('loop.created');
      this.analysisLoop = new Models.AnalysisLoop();
    } catch (err) {
      this.errorService.handleError(err);
    }
  }

  private async changeCouplingType(event) {
    this.selectedCouplingId = event.detail.value;
    const data = await this.couplingService.get(this.selectedCouplingId);
    this.analysisLoop.MinBreakingStrength = data.MBL;
  }
  
  protected async getLoopTypes() {
    const res = await this.loopTypeService.getAllCached();
    return res.filter((x) => x.NavisionProductId || x.DeltaProductId || x.IsDeleted);
  }

  protected async getLoopTypesUsedNet() {
    const res = await this.loopTypeService.getAllCached();
    return res.filter((x) => (!x.NavisionProductId && !x.DeltaProductId) || x.IsDeleted);
  }

  protected cancel() {
    this.eventAggregator.publish('loopFormNewClose', 1);
  }
}
