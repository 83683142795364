import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { AnalysisLoopService } from 'services/analysis-loop-service';
import { LoopPositionHorizontalService } from 'services/loop-position-horizontal-service';
import { LoopPositionVerticalService } from 'services/loop-position-vertical-service';
import { ToastService } from 'services/toast-service';
import { AnalysisLoopBase } from './analysis-loop-base';
import { CouplingService } from 'services';
import { LoopTypeService } from 'services/loop-type-service';

@containerless
@autoinject
export class AnalysisLoopFormInlineEdit extends AnalysisLoopBase {
  @bindable protected minimumBreakingStrength: number;

  protected currentLoopType: Models.AnalysisLoop;
  protected actualBreakingStrengthMissing = false;

  constructor(
    private analysisLoopService: AnalysisLoopService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    protected loopTypeService: LoopTypeService,
    protected loopPositionHorizontalService: LoopPositionHorizontalService,
    protected loopPositionVerticalService: LoopPositionVerticalService,
    couplingService: CouplingService
  ) {
    super(couplingService);
  }

  protected async attached() {
    await this.getCouplings();
    this.selectedCouplingId = this.analysisLoop.CouplingId;
  }

  protected async updateLoop() {
    try {
      this.analysisLoop.LoopType = null;
      this.analysisLoop.LoopPositionVertical = null;
      this.analysisLoop.LoopPositionHorizontal = null;
      this.analysisLoop.LoopType = null;
      this.analysisLoop.Coupling = null;

      if (this.selectedCouplingId && this.hasConnectionChecked()) {
        this.analysisLoop.CouplingId = this.selectedCouplingId;

        this.analysisLoop.LoopTypeId = null;
      } else {
        this.analysisLoop.CouplingId = null;
      }
      await this.analysisLoopService.put(this.analysisLoop, this.analysisLoop.Id);
      this.eventAggregator.publish('loopListReset', 1);
      this.eventAggregator.publish('loopFormEditClose', 1);
      this.toastService.showSuccess('loop.updated');
    } catch (err) {
      this.errorService.handleError(err);
    }
  }

  private async changeCouplingType(event) {
    this.selectedCouplingId = event.detail.value;
    const data = await this.couplingService.get(this.selectedCouplingId);
    this.analysisLoop.MinBreakingStrength = data.MBL;
  }
  
  protected async deleteLoop() {
    try {
      await this.analysisLoopService.delete(this.analysisLoop.Id);
      this.eventAggregator.publish('loopFormEditClose', 1);
      this.eventAggregator.publish('loopListReset', 1);
      this.toastService.showSuccess('loop.deleted');
    } catch (err) {
      this.errorService.handleError(err);
    }
  }

  protected cancelEdit() {
    this.eventAggregator.publish('loopFormEditClose', 1);
    this.eventAggregator.publish('loopListReset', 1);
  }
}
