import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { AnalysisNettingService } from 'services/analysis-netting-service';
import { NettingTypeService } from 'services/netting-type-service';
import { PlacementService } from 'services/placement-service';
import { ToastService } from 'services/toast-service';
import { NetMaskTypeService } from 'services/netmask-type-service';
import { NetMaskVariants } from 'models/NetMaskVariants';

@containerless
@autoinject
export class AnalysisNettingFormInlineNew {
  @bindable private analysisId: number;
  @bindable minimumBreakingStrength: number;

  private analysisNetting: Models.AnalysisNetting = new Models.AnalysisNetting();
  private currentNettingType: Models.NettingType;

  actualBreakingStrengthMissing = false;

  constructor(
    private analysisNettingService: AnalysisNettingService,
    private nettingTypeService: NettingTypeService,
    private placementService: PlacementService,
    private netMaskService: NetMaskTypeService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private errorService: ErrorService,
    private netMaskTypeService: NetMaskTypeService
  ) {}

  private async attached() {
    const netMaskTypes = await this.netMaskTypeService.getAll();
    const defaultNetMaskTypeId = netMaskTypes.find((v) => v.Variant === NetMaskVariants.FOUR_SIDED).Id;
    this.analysisNetting.NetMaskTypeId = defaultNetMaskTypeId;
  }

  async getNettingTypes() {
    const nettingTypes = await this.nettingTypeService.getAllCached();
    return nettingTypes.filter((x) => x.NavisionProductId || x.DeltaProductId);
  }

  private createNetting() {
    this.analysisNetting.AnalysisId = this.analysisId;

    this.analysisNetting.MeshSize = null;
    this.analysisNetting.Material = null;
    this.analysisNetting.Placement = null;
    this.analysisNetting.ThreadType = null;

    this.analysisNettingService
      .post(this.analysisNetting)
      .then(() => {
        this.eventAggregator.publish('nettingListReset', 1);
        this.eventAggregator.publish('nettingFormNewClose', 1);
        this.toastService.showSuccess('netting.created');
        this.analysisNetting = new Models.AnalysisNetting();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancel() {
    this.eventAggregator.publish('nettingFormNewClose', 1);
  }

  private async changeNettingType(event) {
    try {
      this.analysisNetting.NettingTypeId = event.detail.value;

      const data = await this.nettingTypeService.get(
        this.analysisNetting.NettingTypeId + '?$expand=MeshSize,Material,ThreadType,NavisionProduct'
      );
      this.currentNettingType = data;

      this.analysisNetting.Solidity = this.currentNettingType.Solidity;
      this.analysisNetting.MeshSizeId = this.currentNettingType.MeshSizeId;
      this.analysisNetting.MeshSize = this.currentNettingType.MeshSize;
      this.analysisNetting.MaterialId = this.currentNettingType.MaterialId;
      this.analysisNetting.Material = this.currentNettingType.Material;
      this.analysisNetting.ThreadTypeId = this.currentNettingType.ThreadTypeId;
      this.analysisNetting.ThreadType = this.currentNettingType.ThreadType;

      if (
        this.analysisNetting.Solidity != 0 &&
        this.analysisNetting.MeshSize !== null &&
        this.analysisNetting.MeshSize !== undefined
      ) {
        this.analysisNetting.NetThreadDiameter =
          (this.analysisNetting.Solidity * this.analysisNetting.MeshSize.MeshSizeMm) / 2;
      } else {
        this.analysisNetting.NetThreadDiameter = 0;
      }

      if (this.currentNettingType.NavisionProduct) {
        this.analysisNetting.ActualBreakingStrength = this.currentNettingType.NavisionProduct.MinBreakingStrength;
      } else {
        this.analysisNetting.ActualBreakingStrength = 0;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
