import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable, containerless } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { AnalysisNettingService } from 'services/analysis-netting-service';
import { NettingTypeService } from 'services/netting-type-service';
import { PlacementService } from 'services/placement-service';
import { ToastService } from 'services/toast-service';
import { NetMaskTypeService } from 'services/netmask-type-service';

@containerless
@autoinject
export class AnalysisNettingFormInlineEdit {
  @bindable private analysisNetting: Models.AnalysisNetting;
  @bindable minimumBreakingStrength: number;

  private currentNettingType: Models.NettingType;
  actualBreakingStrengthMissing = false;

  constructor(
    private analysisNettingService: AnalysisNettingService,
    private nettingTypeService: NettingTypeService,
    private placementService: PlacementService,
    private netMaskService: NetMaskTypeService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  async getNettingTypes() {
    const nettingTypes = await this.nettingTypeService.getAllCached();
    return nettingTypes.filter((x) => x.NavisionProductId || x.DeltaProductId);
  }

  private updateNetting() {
    const AnalysisNetting = JSON.parse(JSON.stringify(this.analysisNetting)) as Models.AnalysisNetting;
    delete AnalysisNetting.Analysis;
    delete AnalysisNetting.Material;
    delete AnalysisNetting.MeshSize;
    delete AnalysisNetting.NetMaskType;
    delete AnalysisNetting.ThreadType;
    delete AnalysisNetting.Placement;
    delete AnalysisNetting.NettingType;

    this.analysisNettingService
      .put(AnalysisNetting, this.analysisNetting.Id)
      .then(() => {
        this.eventAggregator.publish('nettingListReset', 1);
        this.eventAggregator.publish('nettingFormEditClose', 1);
        this.toastService.showSuccess('netting.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteNetting() {
    this.analysisNettingService
      .delete(this.analysisNetting.Id)
      .then(() => {
        this.eventAggregator.publish('nettingFormEditClose', 1);
        this.eventAggregator.publish('nettingListReset', 1);
        this.toastService.showSuccess('netting.deleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEdit() {
    this.eventAggregator.publish('nettingFormEditClose', 1);
    this.eventAggregator.publish('nettingListReset', 1);
  }

  private async changeNettingType(event) {
    try {
      if (!event.detail.value || event.detail.value === this.analysisNetting.NettingTypeId) {
        return;
      }
      this.analysisNetting.NettingTypeId = event.detail.value;

      const data = await this.nettingTypeService.get(
        this.analysisNetting.NettingTypeId + '?$expand=MeshSize,Material,ThreadType,NavisionProduct'
      );

      this.currentNettingType = data;

      this.analysisNetting.MeshSizeId = this.currentNettingType.MeshSizeId;
      this.analysisNetting.MeshSize = this.currentNettingType.MeshSize;

      this.analysisNetting.MaterialId = this.currentNettingType.MaterialId;
      this.analysisNetting.Material = this.currentNettingType.Material;

      this.analysisNetting.ThreadTypeId = this.currentNettingType.ThreadTypeId;
      this.analysisNetting.ThreadType = this.currentNettingType.ThreadType;

      this.analysisNetting.Solidity = this.currentNettingType.Solidity;

      if (this.analysisNetting.Solidity != 0 && this.analysisNetting.MeshSize.MeshSizeMm) {
        this.analysisNetting.NetThreadDiameter =
          (this.analysisNetting.Solidity * this.analysisNetting.MeshSize.MeshSizeMm) / 2;
      } else {
        this.analysisNetting.NetThreadDiameter = 0;
      }

      if (this.currentNettingType.NavisionProduct) {
        this.analysisNetting.ActualBreakingStrength = this.currentNettingType.NavisionProduct.MinBreakingStrength;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
