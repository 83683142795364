import { bindable, autoinject } from 'aurelia-framework';
import { Coupling } from 'models/Connections';
import { Models } from 'models/core';
import { CouplingService } from 'services';

@autoinject
export class AnalysisLoopBase {
  @bindable protected analysisLoop: Models.AnalysisLoop = new Models.AnalysisLoop();
  @bindable protected netStandard: Models.NetStandard;

  protected couplings: Coupling[] = [];

  protected selectedCouplingId?: number;
  protected selectedCoupling?: Coupling;

  constructor(protected couplingService: CouplingService) {}

  protected get is2021Standard() {
    return this.netStandard?.Version == Models.NetStandardVersion.NS9415_2021;
  }

  protected hasConnectionChecked() {
    if (!this.is2021Standard) return false;

    return (
      this.analysisLoop.IsAttachmentPointCage ||
      this.analysisLoop.IsAttachmentPointMageband ||
      this.analysisLoop.IsAttachmentPointPlumb ||
      this.analysisLoop.IsAttachmentPointTopLoop
    );
  }

  protected async getCouplings() {
    let res = await this.couplingService.getAllCached();
    res = res.filter((x) => !x.IsDeleted);
    this.couplings = res;
    return res;
  }
}
